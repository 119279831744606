// auth
export const LOGIN = "login";
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const USER_ROLES = "userRoles";
export const RESOURCES = "resources";
export const PHONE = "phone";
export const EMAIL = "email";
// tabs
export const HOME = "home";
export const SIGNAL_STATUS = "signal-status";
export const SIGNAL_CONTROL = "signal-control";
export const OVERVIEW = "overview";
export const TAB = "tab";
export const CHALLAN_ID = "challan-id";
export const JUNCTION_VIDEOS = "junction-videos";

// finance consts
export const FINANCE = "finance";
export const FINANCE_OVERVIEW = "finance-overview";
export const COLLECTION = "collection";
export const STATION_DEPOSIT = "station-deposit";
export const BANK_DEPOSIT = "bank-deposit";
export const FORGET_PASSWORD = "forget-password";
// traffic consts
export const VIOLATION = "violation";
export const TRAFFIC_VIOLATION = "traffic-violation";
export const TRAFFIC_ANALYTICS = "traffic-analytics";
export const REJECTED_BY_POLICE = "rejected-by-police";
export const REVIEW_STATUS_ID = "review-status-id";
// maintenance consts
export const MAINTENANCE = "maintenance";
export const SIGNAL_SUMMARY = "signal-summary";
export const TICKET_ANALYTICS = "ticket-analytics";
export const TICKET_DETAILS = "ticket-details";
export const PERSON_DATA = "person-data";

// permissions consts
export const VIOLATION_OVERVIEW = "violation-overview";
export const VIOLATION_TRAFFICVIOLATION = "violation-trafficviolation";
export const VIOLATION_POLICEREJECTION = "violation-policerejection";
export const VIDEO_JUNCTION = "video-junction";
export const VIDEO_VEHICLE = "video-vehicle";

// video consts
export const VIDEO = "video";
export const JUNCTION_VIDEO = "junction-video";
export const VEHICLE_VIDEO = "vehicle-video";

export const FINANCE_COLLECTION_OVERVIEW = "collection-overview";
export const FINANCE_CASH_DEPOSIT = "cash-deposit";
export const FINANCE_BANK_DEPOSIT = "bank-deposit";
export const CASH_DEPOSIT_SUPERVISOR = "cash-deposit-supervisor";
export const CASH_DEPOSIT_COLLECTOR = "cash-deposit-collector";
export const BANK_DEPOSIT_SUPERVISOR = "bank-deposit-supervisor";
export const BANK_DEPOSIT_COLLECTOR = "bank-deposit-collector";

// common
export const BEFORE = "before";
export const AFTER = "after";
export const SUCCESS = "success";
export const ERROR = "error";
export const FAILURE = "failure";
export const APPROVE = "approve";
export const APPROVED = "approved";
export const REJECT = "reject";
export const PENDING = "pending";
export const VALID = "1";
export const INVALID = "2";
export const NOT_DEFINED = "not-defined";
export const NOT_ALL_SIGNAL_GOT_RESET = "Not all signal got reset";
export const NOT_ALL_SIGNAL_GOT_SET = "Not all signal got set";
// lights
export const GREEN = "green";
export const RED = "red";
export const YELLOW = "yellow";
export const ALL_GREEN = "all-green";
// signal consts
export const INDIVIDUAL = "individual";
export const CORRIDOR = "corridor";
// filters
export const COUNTRY_ID = "country-id";
export const STATE_ID = "state-id";
export const CITY_ID = "city-id";
export const REGION_ID = "region-id";
export const INTERSECTION_ID = "intersection-id";
export const FROM_DATE = "from-date";
export const TO_DATE = "to-date";
export const SEARCH_TYPE = "search-type";
export const SEARCH_TEXT = "search-text";
export const SORT_BY_DATE = "sort-by-date";
export const VIOLATOR_TYPE = "violator-type";
export const VIOLATION_TYPE = "violation-type";
export const PAYMENT_STATUS = "payment-status";
export const REVIEW_STATUS = "review-status";
export const PAGE = "page";
export const COUNTFILTER = "countFilter";

// signal types
export const STRAIGHT = "straight";
export const LEFT = "left";
export const RIGHT = "right";
export const STRAIGHT_RIGHT = "straight-right";
export const STRAIGHT_LEFT = "straight-left";
export const STRAIGHT_LEFT_RIGHT = "straight-left-right";
export const LEFT_RIGHT = "left-right";
// API's
export const ACCESS_TOKEN_EXPIRE = "access token expire";
export const REFRESH_TOKEN_EXPIRE = "refresh token expire";
export const SOMETHING_WENT_WRONG = "something went wrong";
export const NETWORK_ERROR = "network error";
export const LICENSE_PLATE_NUMBER = "license-plate-number";

// ROLE
export const ADMIN = "admin";
