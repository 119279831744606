export const LOCAL_URL = "http://localhost:5000";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_URL;
export const NODEPROXY_URL = process.env.REACT_APP_NODE_PROXY;
export const NODE_WEBSOCKET = process.env.REACT_APP_NODE_WEB_SOCKET;
export const MAINTENANCE_BASE_URL = process.env.REACT_APP_BASE_URL_MAINTENANCE;
export const OFFLINE_MICROSERVICE_URL =
  process.env.REACT_APP_OFFLINE_MICROSERVICE;
export const CAMERA_BASE_URL = process.env.REACT_APP_BASE_URL_CAMERA;
// export const BASE_URL = "http://192.168.0.194:8080";
