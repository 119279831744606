const typeScale = {
  Desktop: {
    header1: "1.75rem", // 28px
    header2: "1.5rem", // 24px
    header3: "1.25rem", // 20px
    bodyText1: "1.1rem", // 16px
    bodyText2: "0.875rem", // 14px
    bodyText3: "0.75rem", // 12px
  },
  Mobile: {
    header1: "1.6rem",
    header2: "1.5rem",
    header3: "1.142rem",
    bodyText1: "1rem", // 14px
    bodyText2: "0.857rem",
    bodyText3: "0.785rem",
  },
};
export default typeScale;
